import React, { useState } from "react";
import ContactImg from "../../assets/contact.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./contact.css";

const Contact = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [uniName, setUniName] = useState("");
  // const [reqEngCourse, setReqEngCourse] = useState("");
  const [engDiplomaFile, setEngDiplomaFile] = useState("");
  const [reqFile, setReqFile] = useState("");
  const [secondaryFile, setSecondaryFile] = useState("");
  const [diplomaFile, setDiplomaFile] = useState("");
  const [transcriptFile, setTranscriptFile] = useState("");

  const isNotEmpty = !uniName || !engDiplomaFile || !reqFile;

  const handleSubmit = (e) => {
    setLoader(true);
    setError(null);
    e.preventDefault();

    const formData = new FormData();
    formData.append("english_diploma_file", engDiplomaFile);
    formData.append("requirements", reqFile);
    formData.append("secondary_file", secondaryFile);
    formData.append("diploma_file", diplomaFile);
    formData.append("transcript_file", transcriptFile);

    axios
      .post(
        `https://api.admissionminds.com/application/?university_name=${uniName}`,
        formData,
        {
          headers: {
            "secret-key": "U9B6DC3UuNutj5YXM4vfrTWA",
          },
        }
      )
      .then((response) => {
        // Handle the response from the server
        setLoader(false);
        navigate("/thankyou", { state: { message: response.data.result } });
      })
      .catch((error) => {
        // Handle the error
        setError(error?.response?.data?.detail);
        setLoader(false);

        console.error(error);
      });
  };
  return (
    <>
     
      {/* -----------------------------contact form----------------------------- */}
      <div className="contact_container">
        <div className="image">
          <img src={ContactImg} alt="contact" />
        </div>
        <div className="contact_form">
          <form>
            <div className="inputfeilds">
              <label>University Name *</label>
              <input
                type="text"
                value={uniName}
                onChange={(e) => setUniName(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>Requirements *</label>
              <input
                type="text"
                value={reqFile}
                onChange={(e) => setReqFile(e.target.value)}
              />
            </div>
            {/* <div className="inputfeilds">
              <label>Required English Score *</label>
              <input
                type="number"
                value={reqEngCourse}
                onChange={(e) => setReqEngCourse(e.target.value)}
              />
            </div> */}
            <div className="inputfeilds">
              <label>English Diploma File (.pdf) *</label>
              <input
                type="file"
                onChange={(e) => setEngDiplomaFile(e.target.files[0])}
              />
            </div>

            <div className="inputfeilds">
              <label>Secondary File</label>
              <input
                type="file"
                onChange={(e) => setSecondaryFile(e.target.files[0])}
              />
            </div>
            <div className="inputfeilds">
              <label>Diploma File</label>
              <input
                type="file"
                onChange={(e) => setDiplomaFile(e.target.files[0])}
              />
            </div>

            <div className="inputfeilds">
              <label>Transcript File (.pdf)</label>
              <input
                type="file"
                onChange={(e) => setTranscriptFile(e.target.files[0])}
              />
            </div>

            <button
              type="submit"
              className={`btn ${isNotEmpty ? "btn_secondary" : "btn_primary"}`}
              disabled={isNotEmpty || loader}
              onClick={handleSubmit}
            >
              {loader ? "Loading...." : "Submit"}
            </button>
          </form>
          <p className="error_message">{error}</p>
        </div>
      </div>
    </>
  );
};

export default Contact;
