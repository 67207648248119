import React from 'react';

const Footer = () => {
  return (
    <footer style={{display: 'flex', justifyContent: "center", alignItems: "center", padding: '1rem'}}>
      <p style={{textAlign: "center"}}>Copyright &#169; 2023. All rights reserved</p>
    </footer>
  );
}

export default Footer;
