import React from "react";
import { useLocation } from "react-router-dom";
import "./thankyou.css";

import { FaCheck } from "react-icons/fa";
const Thankyou = () => {
  const { state } = useLocation();
  return (
    <div className="thankyou_container">
      <FaCheck className="check-icon"></FaCheck>
      <h1 className="thankyou_title">Here is your feedback on your university application:</h1>
      <p className="thankyou_description">{state?.message}</p>
      <button
        className="btn btn_primary"
        onClick={() => (window.location.href = "/")}
      >
        Start again
      </button>
    </div>
  );
};

export default Thankyou;
