import { Routes, Route } from "react-router-dom";
import "./App.css";
import Contact from "./pages/Contact";
import Thankyou from "./pages/Thankyou";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Contact />} />
        <Route path="/thankyou" element={<Thankyou />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
